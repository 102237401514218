import React from "react";
import styles from "../assets/css/widget.module.scss";
import montana from "../assets/gfx/mountain.png";
import { ReactSVG } from "react-svg";

const Widget = () => (
	<div className={styles.widgetContainer}>
		<section className={styles.widget}>
			<article className={styles.infoLastSnow}>
				<div className={styles.info01}>
					<p>
						<ReactSVG
							src="/img/snowflake.svg"
							className={styles.snowflakeIcon}
							wrapper="span"
						/>
						<span>-13º</span>
					</p>
					<ul>
						<li>
							<p className={styles.title}>Sens. T</p>
							<p className={styles.number}>-22º</p>
						</li>
					</ul>
				</div>
				<div className={styles.info02}>
					<p>Última nevada: 2 de abril 2020</p>
				</div>
			</article>
			<article className={styles.infoForecastHeader}>
				<div className={styles.left}>
					<ul className={styles.tempConverter}>
						<li>Cº</li>
						<li>Fº</li>
					</ul>
					<div className={styles.daysHeights}>
						<img src={montana} alt="" title="" />
						<ul>
							<li>
								<span>2640m</span>
							</li>
							<li className={styles.active}>
								<span>2640m</span>
							</li>
							<li>
								<span>2640m</span>
							</li>
						</ul>
					</div>
				</div>
				<div className={styles.right}>
					<p>últimas 72hs</p>
					<span>
						<ReactSVG src="/img/snowflake-white.svg" />
						10 cm
					</span>
				</div>
			</article>
			<article className={styles.infoForecastDays}>
				<ul className={styles.dateList}>
					{/* DIA */}
					<li className={styles.date}>
						<ul className={styles.dateInfo}>
							<li className={styles.info01}>
								<div className={`${styles.normal} ${styles.closed}`}>
									<p>Lun 20</p>
									<ReactSVG src="/images/clima_iconos/116.svg" />
									<span className={styles.degrees}>10º / -23º</span>
								</div>
								<div className={`${styles.expanded} ${styles.opened}`}>
									<p>Lunes 20</p>
									<ul>
										<li>
											<ReactSVG src="/images/clima_iconos/116.svg" />
											<span>am</span>
										</li>
										<li>
											<ReactSVG src="/images/clima_iconos/116.svg" />
											<span>pm</span>
										</li>
										<li>
											<ReactSVG src="/images/clima_iconos/116.svg" />
											<span>noche</span>
										</li>
									</ul>
								</div>
							</li>
							<li className={styles.info02}>
								<div className={`${styles.normal} ${styles.closed}`}>
									<span className={styles.arrows}></span>
									<span className={styles.line}></span>
								</div>
								<div className={`${styles.expanded} ${styles.opened}`}>
									<ul>
										<li>
											<span>-23º</span>
											<span className={styles.line}></span>
										</li>
										<li>
											<span>-23º</span>
											<span className={styles.line}></span>
										</li>
										<li>
											<span>-23º</span>
											<span className={styles.line}></span>
										</li>
									</ul>
								</div>
							</li>
							<li className={styles.info03}>
								<div className={`${styles.normal} ${styles.closed}`}>
									<p className={styles.snowQty}>
										<ReactSVG src="/img/snowflake-white.svg" />
										10 cm
									</p>
									<p className={styles.rainfallQty}>
										<ReactSVG src="/img/icon.svg" />5 mm
									</p>
									<p className={styles.visibility}>
										Visibilidad
										<span>Mala</span>
									</p>
									<p className={styles.gust}>
										Ráfagas
										<span>60</span>
										<span
											className={`${styles.arrowContainer} ${styles.nw}`}
										>
											<i className={styles.infoArrowLine} />
											<i className={styles.infoArrowTriangle} />
										</span>
									</p>
								</div>
								<div className={`${styles.expanded} ${styles.opened}`}>
									<ul>
										<li>
											<p className={styles.snowQty}>10</p>
											<p className={styles.rainfallQty}>5</p>
											<p className={styles.visibility}>
												Visib.
												<span>Mala</span>
											</p>
											<p className={styles.gust}>
												Ráfagas
												<span>60</span>
												<span
													className={`${styles.arrowContainer} ${styles.nw}`}
												>
													<i className={styles.infoArrowLine} />
													<i
														className={styles.infoArrowTriangle}
													/>
												</span>
											</p>
										</li>
										<li>
											<p className={styles.snowQty}>10</p>
											<p className={styles.rainfallQty}>5</p>
											<p className={styles.visibility}>
												Visib.
												<span>Mala</span>
											</p>
											<p className={styles.gust}>
												Ráfagas
												<span>60</span>
												<span
													className={`${styles.arrowContainer} ${styles.nw}`}
												>
													<i className={styles.infoArrowLine} />
													<i
														className={styles.infoArrowTriangle}
													/>
												</span>
											</p>
										</li>
										<li>
											<p className={styles.snowQty}>10</p>
											<p className={styles.rainfallQty}>5</p>
											<p className={styles.visibility}>
												Visib.
												<span>Mala</span>
											</p>
											<p className={styles.gust}>
												Ráfagas
												<span>60</span>
												<span
													className={`${styles.arrowContainer} ${styles.nw}`}
												>
													<i className={styles.infoArrowLine} />
													<i
														className={styles.infoArrowTriangle}
													/>
												</span>
											</p>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					{/* /DIA */}

					{/* DIA */}
					<li className={styles.date}>
						<ul className={styles.dateInfo}>
							<li className={styles.info01}>
								<div className={styles.normal}>
									<p>Lun 20</p>
									<ReactSVG src="/images/clima_iconos/116.svg" />
									<span className={styles.degrees}>10º / -23º</span>
								</div>
								<div className={styles.expanded}></div>
							</li>
							<li className={styles.info02}>
								<div className={styles.normal}>
									<span className={styles.arrows}></span>
									<span className={styles.line}></span>
								</div>
								<div className={styles.expanded}></div>
							</li>
							<li className={styles.info03}>
								<div className={styles.normal}>
									<p className={styles.snowQty}>
										<ReactSVG src="/img/snowflake-white.svg" />
										10 cm
									</p>
									<p className={styles.rainfallQty}>
										<ReactSVG src="/img/icon.svg" />5 mm
									</p>
									<p className={styles.visibility}>
										Visibilidad
										<span>Mala</span>
									</p>
									<p className={styles.gust}>
										Ráfagas
										<span>60</span>
										<span
											className={`${styles.arrowContainer} ${styles.nw}`}
										>
											<i className={styles.infoArrowLine} />
											<i className={styles.infoArrowTriangle} />
										</span>
									</p>
								</div>
								<div className={styles.expanded}></div>
							</li>
						</ul>
					</li>
					{/* /DIA */}

					{/* DIA */}
					<li className={styles.date}>
						<ul className={styles.dateInfo}>
							<li className={styles.info01}>
								<div className={styles.normal}>
									<p>Lun 20</p>
									<ReactSVG src="/images/clima_iconos/116.svg" />
									<span className={styles.degrees}>10º / -23º</span>
								</div>
								<div className={styles.expanded}></div>
							</li>
							<li className={styles.info02}>
								<div className={styles.normal}>
									<span className={styles.arrows}></span>
									<span className={styles.line}></span>
								</div>
								<div className={styles.expanded}></div>
							</li>
							<li className={styles.info03}>
								<div className={styles.normal}>
									<p className={styles.snowQty}>
										<ReactSVG src="/img/snowflake-white.svg" />
										10 cm
									</p>
									<p className={styles.rainfallQty}>
										<ReactSVG src="/img/icon.svg" />5 mm
									</p>
									<p className={styles.visibility}>
										Visibilidad
										<span>Mala</span>
									</p>
									<p className={styles.gust}>
										Ráfagas
										<span>60</span>
										<span
											className={`${styles.arrowContainer} ${styles.nw}`}
										>
											<i className={styles.infoArrowLine} />
											<i className={styles.infoArrowTriangle} />
										</span>
									</p>
								</div>
								<div className={styles.expanded}></div>
							</li>
						</ul>
					</li>
					{/* /DIA */}

					{/* DIA */}
					<li className={styles.date}>
						<ul className={styles.dateInfo}>
							<li className={styles.info01}>
								<div className={styles.normal}>
									<p>Lun 20</p>
									<ReactSVG src="/images/clima_iconos/116.svg" />
									<span className={styles.degrees}>10º / -23º</span>
								</div>
								<div className={styles.expanded}></div>
							</li>
							<li className={styles.info02}>
								<div className={styles.normal}>
									<span className={styles.arrows}></span>
									<span className={styles.line}></span>
								</div>
								<div className={styles.expanded}></div>
							</li>
							<li className={styles.info03}>
								<div className={styles.normal}>
									<p className={styles.snowQty}>
										<ReactSVG src="/img/snowflake-white.svg" />
										10 cm
									</p>
									<p className={styles.rainfallQty}>
										<ReactSVG src="/img/icon.svg" />5 mm
									</p>
									<p className={styles.visibility}>
										Visibilidad
										<span>Mala</span>
									</p>
									<p className={styles.gust}>
										Ráfagas
										<span>60</span>
										<span
											className={`${styles.arrowContainer} ${styles.nw}`}
										>
											<i className={styles.infoArrowLine} />
											<i className={styles.infoArrowTriangle} />
										</span>
									</p>
								</div>
								<div className={styles.expanded}></div>
							</li>
						</ul>
					</li>
					{/* /DIA */}

					{/* DIA */}
					<li className={styles.date}>
						<ul className={styles.dateInfo}>
							<li className={styles.info01}>
								<div className={styles.normal}>
									<p>Lun 20</p>
									<ReactSVG src="/images/clima_iconos/116.svg" />
									<span className={styles.degrees}>10º / -23º</span>
								</div>
								<div className={styles.expanded}></div>
							</li>
							<li className={styles.info02}>
								<div className={styles.normal}>
									<span className={styles.arrows}></span>
									<span className={styles.line}></span>
								</div>
								<div className={styles.expanded}></div>
							</li>
							<li className={styles.info03}>
								<div className={styles.normal}>
									<p className={styles.snowQty}>
										<ReactSVG src="/img/snowflake-white.svg" />
										10 cm
									</p>
									<p className={styles.rainfallQty}>
										<ReactSVG src="/img/icon.svg" />5 mm
									</p>
									<p className={styles.visibility}>
										Visibilidad
										<span>Mala</span>
									</p>
									<p className={styles.gust}>
										Ráfagas
										<span>60</span>
										<span
											className={`${styles.arrowContainer} ${styles.nw}`}
										>
											<i className={styles.infoArrowLine} />
											<i className={styles.infoArrowTriangle} />
										</span>
									</p>
								</div>
								<div className={styles.expanded}></div>
							</li>
						</ul>
					</li>
					{/* /DIA */}
				</ul>
			</article>
			<article className={styles.bottom}>
				<div className={styles.left}>
					<ReactSVG src="/img/logo-freshsnow.svg" />
					<p>
						Don´t wish <br />
						for the perfect snow day.
						<br /> Find it.
					</p>
				</div>
				<div className={styles.right}>
					<a>
						<p>
							ver 14 días
							<span>+10 cm</span>
						</p>
						<ReactSVG src="/img/snowflake.svg" />
					</a>
					<p>Pronóstico extendido - 14 días</p>
				</div>
			</article>
		</section>
	</div>
);

export default Widget;
